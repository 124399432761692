<template>
  <div id="addEmployee" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base" v-if="empleado">
      <v-row no-gutters class="titulo ml-10 mt-5">AGREGAR EMPLEADO</v-row>
      <v-container fluid class="addForms" style="margin-top: 2% !important">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="5" sm="5" class="marginCol formularios">
            <v-row>
              <div class="form-group">
                <label for="job">Empresa</label>
                <select
                  name="nombreSoftware"
                  id="nombreSoftware"
                  v-model="enterprise"
                  class="inputs"
                  @change="obtenerAreasPuestos()"
                >
                  <option disabled value="0">Selecciona</option>
                  <option
                    v-for="enterprise in enterprises"
                    :key="enterprise.id"
                    v-bind:value="enterprise.id"
                  >
                    {{ enterprise.razonSocial }}
                  </option>
                </select>
              </div>
            </v-row>
            <v-row>
              <div class="form-group">
                <label for="name">Nombre (s)</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="name"
                  required
                  maxlength="20"
                  @keypress="alfaNumerico($event)"
                  @blur="
                    nombreCompuesto();
                    answer();
                  "
                />
              </div>
              <div class="form-group">
                <label for="surname">Apellido Paterno</label>
                <input
                  type="text"
                  name="surname"
                  id="surname"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="surname"
                  required
                  maxlength="20"
                  @keypress="alfaNumerico($event)"
                  @blur="
                    quitaArticulosPaterno();
                    answer();
                  "
                />
              </div>
              <div class="form-group">
                <label for="lastName">Apellido Materno</label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  class="inputs"
                  autocomplete="false"
                  v-model="lastName"
                  maxlength="20"
                  @keypress="alfaNumerico($event)"
                  @blur="
                    quitaArticulosMaterno();
                    answer();
                  "
                />
              </div>
              <div class="form-group">
                <label for="date">Fecha Nacimiento</label>
                <input
                  type="date"
                  name="date"
                  id="date"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="date"
                  required
                  maxlength="20"
                  @blur="answer()"
                />
              </div>
              <div class="form-group">
                <label for="date">Género</label>
                <select name="job" id="job" v-model="gender">
                  <option disabled value="0">Seleccione</option>
                  <option
                    v-for="gen in genders"
                    :key="gen.id"
                    v-bind:value="gen.id"
                  >
                    {{ gen.nombreGenero }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="curp">CURP</label>
                <input
                  type="text"
                  name="curp"
                  id="curp"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  required
                  v-model="curp"
                  maxlength="18"
                />
                <p
                  style="color: red; margin-left: 160px !important"
                  v-if="errorCurp"
                >
                  *Ingresa un CURP válido
                </p>
              </div>
              <div class="form-group">
                <label for="rfc">RFC</label>
                <input
                  type="text"
                  name="rfc"
                  id="rfc"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  required
                  v-model="rfc"
                  maxlength="13"
                  @keypress="validaRfc($event)"
                />
                <p
                  style="color: red; margin-left: 160px !important"
                  v-if="errorRfc"
                >
                  *Ingresa un RFC válido
                </p>
              </div>
              <div class="form-group">
                <label for="imss">Telefóno Personal</label>
                <input
                  type="text"
                  name="cel"
                  id="cel"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="telefonoPersonal"
                  required
                  maxlength="10"
                  @keypress="isNumber($event)"
                />
              </div>
              <div class="form-group">
                <label for="imss">Correo electrónico</label>
                <input
                  type="text"
                  name="correo"
                  id="correo"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="correo"
                  required
                />
              </div>
              <div class="form-group">
                <label for="imss">IMSS</label>
                <input
                  type="text"
                  name="imss"
                  id="imss"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="imss"
                  required
                  maxlength="11"
                  @keypress="isNumber($event)"
                />
              </div>
              <div class="form-group">
                <label for="job">Puesto</label>
                <select name="job" id="job" v-model="job">
                  <option disabled value="0">Selecciona Puesto</option>
                  <option
                    v-for="job in jobs"
                    :key="job.id"
                    v-bind:value="job.id"
                  >
                    {{ job.nombrePuesto }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="select2">Area/Departamento</label>
                <select name="select2" id="select2" v-model="area">
                  <option disabled value="0">Selecciona Area/Depto</option>
                  <option
                    v-for="area in areas"
                    :key="area.id"
                    v-bind:value="area.id"
                  >
                    {{ area.nombreArea }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="select2">Sucursal</label>
                <select
                  name="select2"
                  id="select2"
                  v-model="branch"
                  @change="numeroSucursal()"
                >
                  <option disabled value="0">Selecciona Sucursal</option>
                  <option
                    v-for="branch in branches"
                    :key="branch.id"
                    v-bind:value="branch.id"
                  >
                    {{ branch.nombreSucursal }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label for="imss">Telefóno</label>
                <input
                  type="text"
                  name="ofi"
                  id="ofi"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="telefono"
                  disabled
                  maxlength="10"
                  @keypress="isNumber($event)"
                />
              </div>
              <div class="form-group">
                <label for="imss">Extensión</label>
                <input
                  type="text"
                  name="ext"
                  id="ext"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="ext"
                  required
                  maxlength="6"
                  @keypress="isNumber($event)"
                />
              </div>
              <div class="form-group">
                <label for="dateAdmission">Fecha Admisión</label>
                <input
                  type="date"
                  name="dateAdmission"
                  id="dateAdmission"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="dateAdmission"
                  required
                  maxlength="20"
                />
              </div>
            </v-row>
            <v-row>
              <div class="form-group">
                <label for="sueldo">Sueldo de nomina</label>
                <input
                  type="text"
                  name="sueldo"
                  id="sueldo"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="pay"
                  required
                  maxlength="5"
                  @keypress="isNumber($event)"
                />
              </div>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            md="6"
            sm="6"
            class="mt-5"
            align-self="center"
          >
            <v-row justify="center">
              <button class="botonAmarillo" @click="regresar()">
                Cancelar
              </button>
              <button class="botonAmarilloDerecho" @click="validacion()">
                Continuar
              </button>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <v-container class="base" v-if="dataCfdi">
      <v-row no-gutters class="titulo ml-10 mt-5">DATOS PARA CFDI</v-row>
      <v-container fluid class="addForms">
        <v-row no-gutters justify="end">
          <v-col
            cols="12"
            lg="5"
            md="5"
            sm="5"
            class="marginCol formulariosTargetL"
          >
            <v-row>
              <div class="form-group">
                <label for="labor">Antigüedad</label>
                <input
                  type="text"
                  name="labor"
                  id="labor"
                  placeholder="Obligatorio"
                  class="inputs mr-2"
                  autocomplete="false"
                  v-model="labor"
                  required
                  maxlength="1"
                  @keypress="isNumber($event)"
                />Años
              </div>
              <div class="form-group">
                <label for="typeContract">Tipo de Contrato</label>
                <select
                  name="typeContract"
                  id="typeContract"
                  v-model="contract"
                >
                  <option selected disabled value="0">
                    Selecciona Contrato
                  </option>
                  <option
                    v-for="contract in contracts"
                    :key="contract.id"
                    v-bind:value="contract.id"
                  >
                    {{ contract.tipoContrato }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="typeDay">Tipo de Jornada</label>
                <select name="typeDay" id="typeDay" v-model="day">
                  <option disabled value="0">Selecciona Jornada</option>
                  <option
                    v-for="day in days"
                    :key="day.id"
                    v-bind:value="day.id"
                  >
                    {{ day.nombreJornada }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="risk">Riesgo del Puesto</label>
                <select name="risk" id="risk" v-model="risk">
                  <option disabled value="0">Selecciona Riesgo</option>
                  <option
                    v-for="risk in risks"
                    :key="risk.id"
                    v-bind:value="risk.id"
                  >
                    {{ risk.nombreRiesgo }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="union">Sindicalizado</label>
                <select name="union" id="union" v-model="union">
                  <option disabled value="0">Selecciona Sindicalizado</option>
                  <option selected value="1">Si</option>
                  <option value="2">No</option>
                </select>
              </div>
              <div class="form-group">
                <label for="regime">Tipo de Régimen</label>
                <select name="regime" id="regime" v-model="regime">
                  <option disabled value="0">Selecciona Regimen</option>
                  <option
                    v-for="regime in regimes"
                    :key="regime.id"
                    v-bind:value="regime.id"
                  >
                    {{ regime.nombreRegimen }}
                  </option>
                </select>
              </div>
              <div class="form-group mb-3">
                <label for="periodicity">Periodicidad de pago</label>
                <select
                  name="periodicity"
                  id="periodicity"
                  v-model="periodicity"
                >
                  <option disabled value="0">Selecciona Periodicidad</option>
                  <option
                    v-for="periodicity in periodicities"
                    :key="periodicity.id"
                    v-bind:value="periodicity.id"
                  >
                    {{ periodicity.nombrePeriodicidad }}
                  </option>
                </select>
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" align-self="center">
            <v-row justify="center">
              <button class="botonAmarillo" @click="cancelar()">
                Cancelar
              </button>
              <button class="botonAmarilloDerecho" @click="validacionData()">
                Siguiente
              </button>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <v-container class="base" v-if="dataBank">
      <v-row no-gutters class="titulo ml-10 mt-5">DATOS BANCARIOS</v-row>
      <v-container fluid class="addCategory2">
        <v-row no-gutters justify="end" class="formulariosTarget">
          <v-col cols="12" lg="5" md="4" sm="6" class="ml-5">
            <v-row>
              <div class="form-group mt-2">
                <label for="bank">Banco</label>
                <select name="banck" id="bank" v-model="bank">
                  <option disabled value="0">Selecciona Banco</option>
                  <option
                    v-for="bank in banks"
                    :key="bank.id"
                    v-bind:value="bank.id"
                  >
                    {{ bank.nombreCorto }}
                  </option>
                </select>
              </div>
            </v-row>
            <v-row>
              <div class="form-group mt-2">
                <label for="count">Cuenta</label>
                <input
                  type="text"
                  name="count"
                  id="count"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="count"
                  required
                  maxlength="10"
                  @keypress="isNumber($event)"
                />
              </div>
            </v-row>
            <v-row>
              <div class="form-group mt-2">
                <label for="key">Cuenta clave</label>

                <input
                  type="text"
                  name="key"
                  id="key"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="key"
                  required
                  maxlength="18"
                  @keypress="isNumber($event)"
                />
              </div>
            </v-row>
            <v-row>
              <div class="form-group mt-2">
                <label for="nomina">Anticipo de nomina</label>
                <select name="nomina" id="nomina" v-model="nomina">
                  <option selected disabled value="0">
                    Selecciona anticipo
                  </option>
                  <option value="1">Si</option>
                  <option value="2">No</option>
                </select>
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" align-self="center">
            <v-row justify="center">
              <button class="botonAmarillo" @click="regresar()">
                Regresar
              </button>
              <button class="botonAmarilloDerecho" @click="validacionBank()">
                Guardar
              </button>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-sm-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
          <ul>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            {{ respuesta }}
          </h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancelar()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="aux()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      enterprises: [],
      enterprise: localStorage.empresaIdGlobal,
      dialog: false,
      selectedItem: null,
      message: "",
      //seccions

      dataCfdi: false,
      empleado: true,
      dataBank: false,
      //Datos Empleado
      name: "",
      surname: "",
      lastName: "",
      date: "",
      curp: "",
      rfc: "",
      telefono: "",
      telefonoPersonal: "",
      ext: "",
      correo: "",
      imss: "",
      job: 0,
      area: 0,
      branch: 0,
      dateAdmission: "",
      errorRfc: false,
      errorCurp: false,
      pay: 0,
      //Arrays para empleado
      jobs: [],
      areas: [],
      branches: [],
      //dialogs de empleado
      errors: [],
      error: false,
      respuesta: "",
      confirmation: false,
      advice: false,
      //Data CFDI
      labor: "",
      contract: 0,
      day: 0,
      risk: 0,
      union: 2,
      regime: 0,
      periodicity: 0,

      //Arrays para CFDI

      contracts: [],
      days: [],
      risks: [],
      regimes: [],
      periodicities: [],
      //Datos Bancobank: ""
      bank: 0,
      count: "",
      key: "",
      nomina: 2,
      auxName: "",
      auxSurname: "",
      auxLastName: "",
      banks: [],
      gender: 0,
      genders: [],
    };
  },
  methods: {
    numeroSucursal() {
      let suc = this.branches.find((sucursal) => sucursal.id === this.branch);
      this.telefono = suc.telefono;
    },
    listarGeneros() {
      this.show = true;
      axios
        .get(Server + "/genero", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.genders = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listarBancos() {
      this.show = true;
      axios
        .get(Server + "/bancos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.banks = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    //regresa al listado de empleados una vez agregado un nuevo empleado
    aux() {
      this.confirmation = false;
      this.$router.push("/employee");
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    validaCurp() {
      var strCorrecta = this.curp;
      if (strCorrecta.length === 18) {
        return true;
      } else {
        this.errors.push("El CURP esta incompleto favor de verificarlo.");
        return false;
      }
    },
    validaRfc() {
      var strCorrecta;
      strCorrecta = this.rfc;
      if (this.rfc.length == 12) {
        var valid = "^(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))";
      } else {
        var valid =
          "^(([A-Z]|[a-z]|s){1})(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))";
      }
      var validRfc = new RegExp(valid);
      var matchArray = strCorrecta.match(validRfc);
      if (matchArray == null) {
        this.errors.push("El RFC es incorrecto favor de verificarlo.");
        return false;
      } else {
        //console.log("Correcto");
        return true;
      }
    },
    validaImss() {
      //console.log(this.imss);
      var imss = this.imss;
      if (imss.length != 11) {
        this.errors.push("Número de IMSS incorrecto");
        return false;
      } else {
        //console.log("Correcto",imss.length);
        return false;
      }
    },
    validarEmail() {
      //console.log("entro a Validar Email");
      var exp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.correo != "") {
        if (exp.test(this.correo)) {
          //console.log("El correo esta bien");
          return false;
        } else {
          this.errors.push("Se debe especificar un correo electrónico válido.");
          //console.log("El correo no esta bien");
          return true;
        }
      }
    },
    validacion() {
      //console.log("ID DE LA EMPRESA ES: " + this.enterprise);
      this.errors = [];
      this.validaCurp();
      this.validaRfc();
      this.validaImss();
      this.validarEmail();
      this.message = "Empleado";
      if (this.employeeNumber == "" || this.employeeNumber == 0) {
        this.errors.push("Se debe especificar el número de empleado.");
      }
      if (this.name == "" || this.name == " ") {
        this.errors.push("Se debe especificar el nombre del empleado.");
      }
      if (this.surname == "" || this.surname == " ") {
        this.errors.push("Se debe especificar el apellido paterno.");
      }
      if (this.date == "") {
        this.errors.push("Se debe especificar la fecha de nacimiento.");
      }
      if (
        this.telefonoPersonal.length != 0 &&
        this.telefonoPersonal.length != 10
      ) {
        this.errors.push("Se debe ingresar un número telefónico valido.");
      }
      if (this.gender == 0) {
        this.errors.push("Se debe especificar el género.");
      }
      if (this.job == 0 || this.job == "") {
        this.errors.push("Se debe especificar el puesto.");
      }
      if (this.area == "" || this.area == 0) {
        this.errors.push("Se debe especificar el area.");
      }
      if (this.dateAdmission == "") {
        this.errors.push("Se debe especificar la fecha de admisión.");
      }
      if (this.pay == "" && this.pay == 0) {
        this.errors.push("Se debe especificar el sueldo.");
      }
      if (this.errors.length == 0) {
        this.respuesta = "Esta seguro de la reactivación del empleado.";
        this.continuar();
      } else {
        this.respuesta = "Por favor corrige el siguiente error:";
        this.error = true;
      }
    },
    //Valida el tamaño de la cuenta bancaria
    validaCount() {
      //console.log(this.count);
      var count = this.count;
      if (count.length != 10) {
        this.errors.push("Número de Cuenta incorrecto");
        return false;
      } else {
        //console.log("Correcto",count.length);
        return false;
      }
    },
    //Valida el tamaño de la clabe bancaria
    validaKey() {
      //console.log(this.key);
      var key = this.key;
      if (key.length != 18) {
        this.errors.push("Número de Cuenta Clave incorrecto");
        return false;
      } else {
        //console.log("Correcto",key.length);
        return false;
      }
    },
    validacionData() {
      this.errors = [];
      this.message = "CFDI";
      if (this.labor == "" || this.labor == " ") {
        this.errors.push("Se debe especificar la antigüedad.");
      }
      if (this.contract == "" || this.contract == 0) {
        this.errors.push("Se debe especificar el tipo de contrato.");
      }
      if (this.day == "" || this.day == 0) {
        this.errors.push("Se debe especificar el tipo de jornada.");
      }
      if (this.risk == "" || this.risk == 0) {
        this.errors.push("Se debe especificar el riesgo del puesto.");
      }
      if (this.union == "" || this.union == 0) {
        this.errors.push("Se debe especificar el sindicato.");
      }
      if (this.regime == 0 || this.regime == "") {
        this.errors.push("Se debe especificar el régimen.");
      }
      if (this.periodicity == "" || this.periodicity == 0) {
        this.errors.push("Se debe especificar la periodicidad del pago.");
      }
      if (this.errors.length == 0) {
        this.respuesta = "Esta seguro de la Actualizar el empleado.";
        this.continuarData();
      } else {
        this.respuesta = "Por favor corrige el siguiente error:";
        this.error = true;
      }
    },
    //valida todos los datos bancarios
    validacionBank() {
      this.errors = [];
      this.message = "Datos Bancarios";
      this.validaCount();
      this.validaKey();
      if (this.bank == 0 || this.bank == "") {
        this.errors.push("Se debe especificar el Banco.");
      }
      if (this.nomina == 0 || this.nomina == "") {
        this.errors.push("Se debe especificar el anticipo.");
      }
      if (this.errors.length == 0) {
        this.message = "Alerta";
        this.respuesta = "Esta seguro de la almacenar el empleado.";
        this.advice = true;
      } else {
        this.respuesta = "Por favor corrige el siguiente error:";
        this.error = true;
      }
    },
    continuar() {
      //console.log("Llegaron valores");
      this.empleado = false;
      this.dataBank = false;
      this.dataCfdi = true;
    },
    continuarData() {
      this.dataCfdi = false;
      this.empleado = false;
      this.dataBank = true;
    },
    regresar() {
      this.$router.push("/employee");
    },
    //quita ARTICULOS del nombre compuesto
    quitaArticulos(nombre) {
      this.auxName = nombre;
      this.auxName = this.auxName
        .replace("LA ", "")
        .replace("DE ", "")
        .replace("SA DE CV", "")
        .replace("LOS ", "")
        .replace("LAS ", "")
        .replace("Y ", "")
        .replace("MC ", "")
        .replace("SA ", "")
        .replace("VON ", "")
        .replace("CIA ", "")
        .replace("DEL ", "")
        .replace("SOC ", "")
        .replace("COOP ", "")
        .replace("A EN P ", "")
        .replace("MAC ", "")
        .replace("S EN C ", "")
        .replace("VAN ", "")
        .replace("EN ", "")
        .replace("MI ", "")
        .replace("CON ", "")
        .replace("SUS ", "")
        .replace("SC ", "")
        .replace("SCS ", "")
        .replace("THE ", "")
        .replace("AND ", "")
        .replace("CO ", "")
        .replace("A ", "")
        .replace("SA DE CV MI ", "")
        .replace("COMPA&ÍA ", "")
        .replace("SRL MI", "");
      //console.log("sin articulos" + this.auxName);
      return this.auxName;
    },
    //elimina articulos del apellido paterno
    quitaArticulosPaterno() {
      this.auxSurname = this.surname.toUpperCase();
      //console.log("quitar ARTICULOS apellido Paterno" + this.auxSurname);
      this.auxSurname = this.auxSurname
        .replace("LA ", "")
        .replace("DE ", "")
        .replace("SA DE CV", "")
        .replace("LOS ", "")
        .replace("LAS ", "")
        .replace("Y ", "")
        .replace("MC ", "")
        .replace("SA ", "")
        .replace("VON ", "")
        .replace("CIA ", "")
        .replace("DEL ", "")
        .replace("SOC ", "")
        .replace("COOP ", "")
        .replace("A EN P ", "")
        .replace("MAC ", "")
        .replace("S EN C ", "")
        .replace("VAN ", "")
        .replace("EN ", "")
        .replace("MI ", "")
        .replace("CON ", "")
        .replace("SUS ", "")
        .replace("SC ", "")
        .replace("SCS ", "")
        .replace("THE ", "")
        .replace("AND ", "")
        .replace("CO ", "")
        .replace("A ", "")
        .replace("SA DE CV MI ", "")
        .replace("COMPA&ÍA ", "")
        .replace("SRL MI", "");
      //console.log("sin articulos" + this.auxSurname);
      return this.auxSurname;
    },
    //elimina articulos del apellido materno
    quitaArticulosMaterno() {
      this.auxLastName = this.lastName.toUpperCase();
      //console.log("quitar ARTICULOS apellido Paterno" + this.auxSurname);
      this.auxLastName = this.auxLastName
        .replace("LA ", "")
        .replace("DE ", "")
        .replace("SA DE CV", "")
        .replace("LOS ", "")
        .replace("LAS ", "")
        .replace("Y ", "")
        .replace("MC ", "")
        .replace("SA ", "")
        .replace("VON ", "")
        .replace("CIA ", "")
        .replace("DEL ", "")
        .replace("SOC ", "")
        .replace("COOP ", "")
        .replace("A EN P ", "")
        .replace("MAC ", "")
        .replace("S EN C ", "")
        .replace("VAN ", "")
        .replace("EN ", "")
        .replace("MI ", "")
        .replace("CON ", "")
        .replace("SUS ", "")
        .replace("SC ", "")
        .replace("SCS ", "")
        .replace("THE ", "")
        .replace("AND ", "")
        .replace("CO ", "")
        .replace("A ", "")
        .replace("SA DE CV MI ", "")
        .replace("COMPA&ÍA ", "")
        .replace("SRL MI", "");
      //console.log("sin articulos" + this.auxLastName);
      return this.auxLastName;
    },
    vocales(letra) {
      if (
        letra == "A" ||
        letra == "E" ||
        letra == "I" ||
        letra == "O" ||
        letra == "U" ||
        letra == "a" ||
        letra == "e" ||
        letra == "i" ||
        letra == "o" ||
        letra == "u"
      )
        return true;
      else return false;
    },
    //elimina las palabras inconvenientes establecidas en el rfc
    palabraInconveniente(palabra) {
      palabra = palabra
        .replace("BUEI", "BUEX")
        .replace("KOGE", "KOGX")
        .replace("BUEY", "BUEX")
        .replace("KOJO", "KOJX")
        .replace("CACA", "CACX")
        .replace("KAKA", "KAKX")
        .replace("CACO", "CACX")
        .replace("KULO", "KULX")
        .replace("CAGA", "CAGX")
        .replace("MAME", "MAMX")
        .replace("CAGO", "CAGX")
        .replace("MAMO", "MAMX")
        .replace("CAKA", "CAKX")
        .replace("MEAR", "MEAX")
        .replace("COGE", "COGX")
        .replace("MEON", "MEOX")
        .replace("COJA", "COJX")
        .replace("MION", "MIOX")
        .replace("COJE", "COJX")
        .replace("MOCO", "MOCX")
        .replace("COJI", "COJX")
        .replace("MULA", "MULX")
        .replace("COJO", "COJX")
        .replace("PEDA", "PEDX")
        .replace("CULO", "CULX")
        .replace("PEDO", "PEDX")
        .replace("FETO", "FETX")
        .replace("PENE", "PENX")
        .replace("GUEY", "GUEX")
        .replace("PUTA", "PUTX")
        .replace("JOTO", "JOTX")
        .replace("PUTO", "PUTX")
        .replace("KACA", "KACX")
        .replace("QULO", "QULX")
        .replace("KACO", "KACX")
        .replace("RATA", "RATX")
        .replace("KAGA", "KAGX")
        .replace("RUIN", "RUIX")
        .replace("KAGO", "KAGX");
      return palabra;
    },
    //valida datos conforme a rfc
    anexo1(letra) {
      letra = letra.toUpperCase();
      letra = letra
        .replace(" ", "00")
        .replace("0", "00")
        .replace("1", "01")
        .replace("2", "02")
        .replace("3", "03")
        .replace("4", "04")
        .replace("5", "05")
        .replace("6", "06")
        .replace("7", "07")
        .replace("8", "08")
        .replace("9", "09")
        .replace("&", "10")
        .replace("A", "11")
        .replace("B", "12")
        .replace("C", "13")
        .replace("D", "14")
        .replace("E", "15")
        .replace("F", "16")
        .replace("G", "17")
        .replace("H", "18")
        .replace("I", "19")
        .replace("J", "21")
        .replace("K", "22")
        .replace("L", "23")
        .replace("M", "24")
        .replace("N", "25")
        .replace("O", "26")
        .replace("P", "27")
        .replace("Q", "28")
        .replace("R", "29")
        .replace("S", "32")
        .replace("T", "33")
        .replace("U", "34")
        .replace("V", "35")
        .replace("W", "36")
        .replace("X", "37")
        .replace("Y", "38")
        .replace("Z", "39")
        .replace("Ñ", "40");
      return letra;
    },
    nombreCompuesto() {
      //console.log("LLEGA DIRECTO" + this.name);
      let aux = this.name.toUpperCase();
      aux = aux.replace("MARIA ", "").replace("JOSE ", "");
      //console.log("NOMBRE COMPUESTO" + aux);
      return this.quitaArticulos(aux);
    },
    cancelar() {
      (localStorage.idEmpresa = ""), (this.idEmpresa = "");
      this.name = "";
      this.surname = "";
      this.lastName = "";
      this.date = "";
      this.rfc = "";
      this.imss = "";
      this.job = 0;
      this.area = 0;
      this.dateAdmission = "";
      //data cfdi

      this.labor = "";
      this.contract = 0;
      this.day = 0;
      this.risk = 0;
      this.union = 2;
      this.regime = 0;
      this.periodicity = 0;
      //data bank
      this.bank = 0;
      this.count = "";
      this.key = "";
      this.nomina = 2;
      //data CFDI

      this.$router.push("/employee");
    },
    //guarda un nuevo empleado
    guardar() {
      this.advice = false;
      this.show = true;
      axios
        .post(
          Server + "/empleados",
          {
            EmpresaId: this.enterprise,
            Nombre: this.name,
            ApellidoPaterno: this.surname,
            ApellidoMaterno: this.lastName,
            FechaNacimiento: this.date,
            GeneroId: this.gender,
            CURP: this.curp,
            RFC: this.rfc,
            TelefonoCelular: this.telefonoPersonal == "" ? null : parseInt(this.telefonoPersonal),            
            TelefonoExtension: this.ext == "" ? null : parseInt(this.ext),
            Email: this.correo,
            NSS: this.imss,
            PuestoId: this.job,
            AreaId: this.area,
            SucursalId: this.branch,
            FechaAdmision: this.dateAdmission,
            Sueldo: parseFloat(this.pay),
            //data CFDI
            Antiguedad: this.labor,
            ContratoId: this.contract,
            JornadaId: this.day,
            RiesgoId: this.risk,
            Sindicalizado: this.union == "1" ? true : false,
            IdRegimen: this.regime,
            IdPeriodicidadPago: this.periodicity,
            //data Bank
            BancoId: this.bank,
            Cuenta: this.count,
            CuentaClabe: this.key,
            AnticipoNomina: this.nomina == "1" ? true : false,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          this.enterprise = "";
          this.employeeNumber = "";
          this.name = "";
          this.surname = "";
          this.lastName = "";
          this.rfc = "";
          this.imss = "";
          this.job = 0;
          this.area = 0;
          this.branch = "";
          this.dateAdmission = "";
          this.labor = "";
          //data CFDI
          this.contract = 0;
          this.day = 0;
          this.risk = 0;
          this.union = 2;
          this.regime = 0;
          this.periodicity = 0;
          //data Bank
          this.count = "";
          this.bank = 0;
          this.key = "";
          this.nomina = 2;
          this.message = "Confirmación";
          this.respuesta = "El Empleado fue almacenado con éxito";
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.enterprise = "";
            this.name = "";
            this.surname = "";
            this.lastName = "";
            this.date = "";
            this.rfc = "";
            this.imss = "";
            this.job = 0;
            this.area = 0;
            this.correo = "";
            this.telefono = "";
            this.telefonoPersonal = "";
            this.ext = "";
            this.dateAdmission = "";
            //data cfdi

            this.labor = "";
            this.contract = 0;
            this.day = 0;
            this.risk = 0;
            this.union = 1;
            this.regime = 0;
            this.periodicity = "";
            //data bank
            this.bank = "";
            this.count = "";
            this.key = "";
            this.nomina = 2;
            this.message = "Aviso";
            this.respuesta = "El Empleado no fue almacenado.";
            this.confirmation = true;
          }
        });
    },
    //inicia el valor del rfc
    answer() {
      this.rfc = "";
      if (this.auxSurname == "") {
        this.rfc += this.auxLastName.substr(0, 1);
        var l = this.auxLastName.length;
        var c;
        if (l > 1) {
          for (var i = 1; i < l; i++) {
            c = this.auxLastName.charAt(i);
            if (this.vocales(c)) {
              this.rfc += c;
              break;
            }
          }
          this.rfc += this.auxName.substr(0, 2);
        } else {
          c = "X";
          this.rfc += c;
          this.rfc += this.auxName.substr(0, 2);
        }
      } else if (this.auxLastName == "") {
        this.rfc += this.auxSurname.substr(0, 1);
        var l = this.auxSurname.length;
        var c;
        if (l > 1) {
          for (var i = 1; i < l; i++) {
            c = this.auxSurname.charAt(i);
            if (this.vocales(c)) {
              this.rfc += c;
              break;
            }
          }
          // rfc += apellidoMaterno.substr(0, 1);
          this.rfc += this.auxName.substr(0, 2);
        } else {
          c = "X";
          this.rfc += c;
          this.rfc += this.auxName.substr(0, 2);
        }
      } else {
        this.rfc += this.auxSurname.substr(0, 1);
        var l = this.auxSurname.length;
        var c;
        if (l > 1) {
          for (var i = 1; i < l; i++) {
            c = this.auxSurname.charAt(i);
            if (this.vocales(c)) {
              this.rfc += c;
              break;
            }
          }
          this.rfc += this.auxLastName.substr(0, 1);
          this.rfc += this.auxName.substr(0, 1);
        } else {
          this.rfc += this.auxLastName.substr(0, 1);
          this.rfc += this.auxName.substr(0, 2);
        }
      }
      this.rfc += this.date.substr(2, 2);
      this.rfc += this.date.substr(5, 2);
      this.rfc += this.date.substr(8, 2);
      this.rfc = this.palabraInconveniente(this.rfc);
      this.curp = this.rfc;
      //console.log("RFC CREADO" + this.rfc);
    },
    //obtiene todas las areas, puestos y sucursales por empresa
    obtenerAreasPuestos() {
      this.areas = [];
      this.show = true;
      axios
        .get(Server + "/areas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.enterprise) {
              this.areas.push({
                id: value.id,
                nombreArea: value.nombreArea,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.jobs = [];
      this.show = true;
      axios
        .get(Server + "/puestos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.enterprise) {
              this.jobs.push({
                id: value.id,
                nombrePuesto: value.nombrePuesto,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.branches = [];
      this.show = true;
      axios
        .get(Server + "/sucursales/por-empresa/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log("sucursales", response);
          this.branches = response.data;
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    //obtiene las jornada y riesgos de puestos
    listarJornadas() {
      this.show = true;
      axios
        .get(Server + "/jornadas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.days = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.show = true;
      axios
        .get(Server + "/riesgosPuesto", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.risks = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    //obtiene las peridiodicades de pago
    listarPeriodicidad() {
      this.show = true;
      axios
        .get(Server + "/periodicidadesPago", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.periodicities = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    //obtiene los regimenes que existen
    listarRegimen() {
      this.show = true;
      axios
        .get(Server + "/regimen", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.regimes = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    //inicializa valores primordiales
    inicializar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.show = true;
      axios
        .get(Server + "/contratos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.contracts = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.listarJornadas();
      this.listarPeriodicidad();
      this.listarRegimen();
      this.listarBancos();
      this.listarGeneros();
      this.obtenerAreasPuestos();
    },
  },
  created() {
    this.inicializar();
  },
};
</script>